<template>
  <CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0" style="width: 100%;"
    :show.sync="showDropdown" id="theHeaderAccDropdown">
    <template #toggler>
      <CHeaderNavLink class="p-0">
        <div id="theHeaderAccAvatar" class="c-avatar" style="width: 30px;height: 30px;">
          <img id="theHeaderAccAvatarThai" alt="Thai" src="/img/languages/th_icon.png" class="c-avatar-img "
            v-if="$i18n.locale === 'th'" />
          <img id="theHeaderAccAvatarEng" alt="Eng" src="/img/languages/en_icon.png" class="c-avatar-img " v-else />
        </div>
      </CHeaderNavLink>
    </template>
    <CDropdownItem v-if="$i18n.locale === 'en'" @click="changeLocale('th')">
      <div id="theHeaderAccAvatarThaiOption" class="c-avatar" style="width: 30px;height: 30px;">
        <img id="theHeaderAccThaiFlag" alt="Thai" src="/img/languages/th_icon.png" class="c-avatar-img "
          @click="changeLocale('th')" />
      </div>
      <span id="theHeaderAccThaiLabel" style="margin-left: 20px;"> ภาษาไทย </span>
    </CDropdownItem>
    <CDropdownItem id="theHeaderAccChangeToEng" v-if="$i18n.locale === 'th'" @click="changeLocale('en')">
      <div id="theHeaderAccAvatarEngOption" class="c-avatar" style="width: 30px; height: 30px;">
        <img id="theHeaderAccEngFlag" alt="Eng" src="/img/languages/en_icon.png" class="c-avatar-img "
          @click="changeLocale('en')" />
      </div>
      <span id="theHeaderAccEngLabel" style="margin-left: 20px;"> English </span>
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from '@/plugins/i18n'
import pos from '@/services/local'

export default {
  data() {
    return {
      showDropdown: false,
    }
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date', 'shopsetting']),
    datashop: {
      get() {
        return this.$store.getters.shopsetting
      },
      set(newValue) {
        return this.$store.dispatch('setShopSetting', newValue)
      },
    },
  },
  methods: {
    changeLocale(locale) {
      this.datashop.language = locale
      this.$i18n.locale = locale
      this.showDropdown = false
      const uid = `${localStorage.getItem('shopsUid')}`
      let shops = this.shops
      for (let i = 0; i < shops.length; i++) {
        shops[i].language = locale
        pos({
          method: 'post',
          url: '/api/v1.0/' + uid + '/Shop/updateconfig',
          data: shops[i],
        })
          .then(() => {
            setTimeout(() => { }, 1000)
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
  },
}
</script>
