<template>
  <div style="margin-top: -40px;" :style="{
    backgroundColor: '#F6F6F6',
    
  }">
    <CHeaderNav placement="bottom-end" class="ml-auto p-0"
      style="padding: 0px !important; display: flex; justify-content: flex-end;">
      <TheHeaderAcc />
    </CHeaderNav>
    <CContainer class="d-flex align-items-center min-vh-100  contain-login-card  " style="background-color: '#F6F6F6';">
      <!-- <CCol class=""> -->
        <div class="">
          <CCard  class="align-items-center align-self-center   "  
            style="border-radius:10px; background-color: #F6F6F6;border: 0px solid;">
            <CCardBody style=" background-color: #F6F6F6;">
              <CAlert v-if="alert != ''" color="danger" id="alert">{{ alert }}</CAlert>
              <CRow>
                <CCol lg=6 md="6"  class="form-container" >
                  <h1 class="font-weight-normal mt-3" style="text-align: left;">
                    <img src="/logo_green.png" style="width:60%" class="img-fluid" id="logo" />
                  </h1>
                  <br/>
                  <h3 class="text-left font-weight-normal text-black mb-1 " id="signin-title">
                    {{ $t('loginToPOS') }}
                  </h3>
                  <p class="text-left "> {{ $t('loginDes') }}</p>

                  <CForm @keyup.enter="login()" class="text-left">
                    <p class="mb-0" >{{ $t('email') }}</p>
                    <CInput v-model="email" type="email" id="email-input"  :placeholder="$t('email')">
                    </CInput>
                    <p  class="mb-0">{{ $t('password') }}</p>
                    <CInput v-model="password" type="password" id="password-input" :placeholder="$t('password')"
                      autocomplete="curent-password">
                    </CInput>
                    <router-link to="/forgotpassword" id="forget-password-button">
                      <p class="text-left text-success" id="forget-password-text">{{ $t('forgotPassword') }}</p>
                    </router-link>
                    <CRow>
                      <CCol col="12" v-if="loadingButton === true">
                        <CButton @click="login" block color="success" class="px-4" id="sign-in-button">
                          {{ $t('signIn') }}
                        </CButton>
                      </CCol>
                      <CCol col="12" v-else-if="loadingButton === false">
                        <CButton block color="success" disabled id="loading-button">
                          <CSpinner color="white"  />
                        </CButton>
                      </CCol>
                    </CRow>
                    <div class="hr-sect">{{ $t('or') }}</div>
                    <CRow>
                      <CCol class="col-lg-12 col-12" >
                        <CButton @click="googleSignIn" block  id="google-sign-in-button"  style="background: #FFFFFF;"
                          class="text-dark btn-outline-light mx-auto">
                          <img src="/img/delivery/google.png" class="rounded-circle"
                          style="border-radius: 3px; width: 23px;" /> Continue With Google
                        </CButton>
                      </CCol>
                      <!-- <CCol class="col-md-4">
                        <CButton
                          @click="facebookSignIn"
                          block
                          class="mx-auto"
                          size="lg"
                          style="background: #4267b2;color: white; text-align: center;"
                        >
                          <CIcon name="cib-facebook-f" />
                        </CButton>
                      </CCol> -->
                      <CCol class="col-lg-12  col-12">
                        <CButton @click="appleSignIn" color="dark"   block id="apple-sign-in-button" class="mx-auto mt-3 " >
                          <CIcon name="cib-apple"></CIcon> Continue With Apple
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCol>
                <CCol lg="6" md="6" class="image-container">
                  <img src="/img/alert_login.png" alt="QR Order" class="responsive-image" />
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </div>
      <!-- </CCol> -->
    </CContainer>
  </div>
</template>

<script>
import firebase from 'firebase/app'
import pos from '@/services/local'
import { mapGetters } from 'vuex'
import TheHeaderAcc from '../../containers/TheHeaderAcc.vue'

export default {
  data() {
    return {
      isBusy: true,
      email: '',
      password: '',
      alert: '',
      loadingButton: true,
      showDropdown: false,
    };
  },
  computed: {
    ...mapGetters(['shops', 'users', 'date', 'shopsetting']),
    datashop: {
      get() {
        return this.$store.getters.shopsetting;
      },
      set(newValue) {
        return this.$store.dispatch('setShopSetting', newValue);
      },
    },
  },
  methods: {
    changeLocale(locale) {
      this.datashop.language = locale;
      this.$i18n.locale = locale;
      this.showDropdown = false;
      const uid = `${localStorage.getItem('shopsUid')}`;
      let shops = this.shops;
      for (let i = 0; i < shops.length; i++) {
        shops[i].language = locale;
        pos({
          method: 'post',
          url: '/api/v1.0/' + uid + '/Shop/updateconfig',
          data: shops[i],
        })
          .then(() => {
            setTimeout(() => { }, 1000);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    login() {
      this.loadingButton = false;
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((user) => {
          const idToken = user.user.ya;
          const uid = user.user.uid;
          localStorage.setItem('idToken', idToken);
          localStorage.setItem('uid', uid);
          this.$store.dispatch('createToken');
          this.getRefresh();
          this.loadingButton = true;
        }, (err) => {
          if(err.message === 'There is no user record corresponding to this identifier. The user may have been deleted.'){
            this.alert = this.$t('userNotFound')
          } else if ( err.message === 'The email address is badly formatted.'){
            this.alert = this.$t('emailBadlyFormatted')
          } else if ( err.message === 'The password is invalid or the user does not have a password.'){
            this.alert = this.$t('passwordInvalid')
          }
          
          this.loadingButton = true;
        })
        .catch((error) => {
          alert('Oops: ' + error.message);
        });
    },
    appleSignIn() {
      const provider = new firebase.auth.OAuthProvider('apple.com');
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          let user = result.user;
          let idToken = user.ya;
          let uid = user.uid;
          localStorage.setItem('idToken', idToken);
          localStorage.setItem('uid', uid);
          this.$store.dispatch('createToken');
          this.getRefresh();
        })
        .catch((err) => {
          this.alert = err.message;
          alert('Oops.' + err.message);
        });
    },
    googleSignIn() {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((user) => {
          const idToken = user.user.ya;
          const uid = user.user.uid;
          localStorage.setItem('idToken', idToken);
          localStorage.setItem('uid', uid);
          this.$store.dispatch('createToken');
          this.getRefresh();
        })
        .catch((err) => {
          this.alert = err.message;
          alert('Oops.' + err.message);
        });
    },
    facebookSignIn() {
      const provider = new firebase.auth.FacebookAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provider)
        .then((user) => {
          const idToken = user.user.ya;
          const uid = user.user.uid;
          localStorage.setItem('idToken', idToken);
          localStorage.setItem('uid', uid);
          this.$store.dispatch('createToken');
          this.getRefresh();
        })
        .catch((err) => {
          this.alert = err.message;
          alert('Oops.' + err.message);
        });
    },
    async getRefresh() {
      try {
        const uid = `${localStorage.getItem('uid')}`;
        const params = { refresh: true };
        const res = await pos.get(`/api/v1.0/${uid}/Shop/accesslist/data`, { params });

        const data = res.data.data || [];
        const shops = [];
        data.forEach((shop) => {
          shop.branch.forEach((branch) => {
            if (branch?.objectId) {
              shops.push(branch);
            }
          });
        });

        const shopsUid = data[0]?.branch[0]?.uid;
        const shopObjectId = data[0]?.branch[0]?.objectId;
        if (shopsUid && shopObjectId) {
          localStorage.setItem('shopObjectId', shopObjectId);
          localStorage.setItem('shopsUid', shopsUid);
          this.$store.dispatch('getPermission');

          const url = new URL(window.location.href);
          const redirectParam = url.searchParams.get('redirect');
          const redirectPath = redirectParam ? decodeURIComponent(redirectParam) : '/';
          window.location.href = redirectPath;
        } else {
          console.error('Invalid response structure');
        }
      } catch (error) {
        console.error('Error during refresh:', error);
      }
    }


  },
  components: { TheHeaderAcc }
}
</script>

<style scoped>
.hr-sect {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  color: rgba(0, 0, 0, 0.35);
  margin: 8px 0px;
}

.hr-sect:before,
.hr-sect:after {
  content: '';
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.35);
  height: 1px;
  font-size: 0px;
  line-height: 0px;
  margin: 0px 8px;
}

.circle-button {
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
}
.login-container {
  background-color: #f6f6f6;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem;
}

.brand-logo img {
  max-width: 150px;
  height: auto;
}

.google-btn {
  background-color: #ffffff;
  color: black;
  border: 1px solid #ccc;
}

.google-icon {
  width: 20px;
  margin-right: 10px;
}

.separator {
  text-align: center;
  margin: 1rem 0;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}





@media (max-width: 460px) {
  .contain-login-card{
    width: 100%;
  }
  .image-container {
    display: none;
  }
}
@media  (max-width: 768px) {
  .contain-login-card{
    width: 100%;
  }
  .image-container {
    display: none;
  }
}
@media  (max-width: 820px) {
  .contain-login-card{
    width: 100%;
  }

}

@media (max-width: 1266px) {
  .contain-login-card{
    width: 70%;
  }
} 
@media (min-width: 1400px) {
  .contain-login-card{
    width: 50%;
  }
} 
</style>
